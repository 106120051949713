import * as React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

function SvgNotFoundProfile() {
  const { isDark } = useTheme();

  return isDark ? (
    <svg
      width={220}
      height={149}
      viewBox="0 0 220 149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#prefix__clip0)">
        <path d="M129.186 0h-81.22v96.15h81.22V0z" fill="#F1F4F9" />
        <path
          d="M126.122 3.024H51.029v90.07h75.093V3.024z"
          fill="url(#prefix__paint0_linear)"
        />
        <path
          d="M65.466 53.525h-9.95v39.38h9.95v-39.38z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path d="M118.508 82.382H89.792v2.993h28.716v-2.993z" fill="#83B4FF" />
        <path
          d="M118.508 80.146H89.792v12.759h28.716v-12.76z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M94.12 67.04H68.31v25.896h25.81V67.04z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M91.75 46.657H70.68v25.896H91.75V46.657z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M87.833 40.986H74.565v35.789h13.268V40.986z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M79.43 28.164h-1.106v35.789h1.106V28.164zM84.137 36.828H83.03v35.788h1.106V36.828zM64.045 55.73l-6.824 2.143V43.57l6.824-2.142V55.73zM113.169 72.68h-19.08v10.396h19.08V72.679z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M111.463 51.54H98.258v35.946h13.205V51.54z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M109.758 61.968h-9.604V45.082l9.604 4.82v12.066z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M58.422 60.928h-1.2v3.214h1.2v-3.214zM61.075 60.928h-1.2v3.214h1.2v-3.214zM63.729 60.928h-1.2v3.214h1.2v-3.214zM58.422 66.473h-1.2v3.213h1.2v-3.213zM61.075 66.473h-1.2v3.213h1.2v-3.213zM63.729 66.473h-1.2v3.213h1.2v-3.213zM118.508 88.368H89.792v2.773h28.716v-2.773z"
          fill="#83B4FF"
        />
        <path
          d="M124.037 69.686h-8.562v23.219h8.562V69.686z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M85.906 76.617h-10.93v16.288h10.93V76.617zM62.529 48.768h-2.054v4.757h2.054v-4.757zM91.75 54.722H70.68v4.064H91.75v-4.064zM102.334 54.722h-2.18v4.883h2.18v-4.883zM107.135 54.722h-2.18v4.883h2.18v-4.883zM102.334 61.527h-2.18v4.883h2.18v-4.883zM107.135 61.527h-2.18v4.883h2.18v-4.883zM102.334 68.3h-2.18v4.883h2.18V68.3zM107.135 68.3h-2.18v4.883h2.18V68.3zM102.334 75.105h-2.18v4.883h2.18v-4.883zM107.135 75.105h-2.18v4.883h2.18v-4.883zM102.334 81.91h-2.18v4.883h2.18V81.91zM107.135 81.91h-2.18v4.883h2.18V81.91z"
          fill="#83B4FF"
        />
        <path
          d="M126.122 3.024v5.608L61.897 93.094H51.03V74.57l54.4-71.546h20.692z"
          fill="url(#prefix__paint1_linear)"
        />
        <path
          d="M98.922 3.024L51.03 66.032V55.289L90.74 3.024h8.182z"
          fill="url(#prefix__paint2_linear)"
        />
        <path
          d="M126.122 37.175v10.774l-34.34 45.145H83.6l42.522-55.92z"
          fill="url(#prefix__paint3_linear)"
        />
        <path
          d="M124.732 3.024L56.21 93.094h-4.264l68.521-90.07h4.265z"
          fill="url(#prefix__paint4_linear)"
        />
      </g>
      <path
        d="M4.416 148.945s-13.239-32.637 6.13-52.127c24.979-25.132 57.253-4.12 69.363-4.738 12.111-.609 12.795-24.727 48.98-27.612 27.975-2.232 73.922-2.407 64.123 36.997-7.452 29.954 34.012 14.437 25.941 47.535l-214.537-.055z"
        fill="url(#prefix__paint5_linear)"
      />
      <g clipPath="url(#prefix__clip1)">
        <path
          opacity={0.2}
          d="M171.229 142.699c15.14 0 27.414-.671 27.414-1.5 0-.828-12.274-1.5-27.414-1.5-15.14 0-27.414.672-27.414 1.5 0 .829 12.274 1.5 27.414 1.5z"
          fill="#513CAF"
        />
        <path
          d="M171.229 133.913a2.36 2.36 0 01-2.375-2.357v-18.602h4.792v18.602c-.043 1.285-1.079 2.357-2.417 2.357z"
          fill="#4E355B"
        />
        <path
          d="M158.752 139.699a.825.825 0 01-.561-.214.823.823 0 01-.043-1.2l12.476-13.716c.173-.171.389-.3.648-.3s.475.086.647.257l12.865 13.716c.346.343.302.9-.043 1.2a.839.839 0 01-1.209-.043l-12.26-13.03-11.872 13.073a.959.959 0 01-.648.257z"
          fill="#4E355B"
        />
        <path
          opacity={0.2}
          d="M173.646 112.954h-4.792v8.786h4.792v-8.786z"
          fill="#5C2C5B"
        />
        <path
          d="M158.752 141.928c1.717 0 3.109-1.382 3.109-3.086s-1.392-3.086-3.109-3.086c-1.716 0-3.108 1.382-3.108 3.086s1.392 3.086 3.108 3.086z"
          fill="#4E355B"
        />
        <path
          d="M158.752 140.342c.835 0 1.511-.672 1.511-1.5 0-.829-.676-1.5-1.511-1.5-.834 0-1.511.671-1.511 1.5 0 .828.677 1.5 1.511 1.5z"
          fill="#74607E"
        />
        <path
          d="M184.094 141.928c1.716 0 3.108-1.382 3.108-3.086s-1.392-3.086-3.108-3.086c-1.717 0-3.109 1.382-3.109 3.086s1.392 3.086 3.109 3.086z"
          fill="#4E355B"
        />
        <path
          d="M194.887 116.126h-47.1s-1.425-21.002-1.425-38.233c0-8.786.389-15.258.734-19.501.302-3.386 3.152-6 6.605-6h35.358c3.453 0 6.303 2.614 6.605 6 .345 3.986.691 10.244.691 19.502-.044 19.201-1.468 38.232-1.468 38.232z"
          fill="url(#prefix__paint6_linear)"
        />
        <path
          d="M148.564 108.11a.85.85 0 01-.604-.257l-1.684-1.671c-2.936-2.915-4.749-5.829-4.749-11.358v-3.772a.863.863 0 011.727 0v3.772c0 4.971 1.554 7.457 4.231 10.115l1.684 1.671a.825.825 0 010 1.2.755.755 0 01-.605.3z"
          fill="url(#prefix__paint7_linear)"
        />
        <path
          d="M147.139 94.266h-6.087c-1.338 0-2.461-1.071-2.461-2.443v-.685a2.451 2.451 0 012.461-2.444h6.087c1.339 0 2.461 1.072 2.461 2.444v.685c-.043 1.329-1.122 2.443-2.461 2.443z"
          fill="url(#prefix__paint8_linear)"
        />
        <path
          d="M193.289 108.11a.854.854 0 01-.604-.257.825.825 0 010-1.2l1.684-1.671c2.676-2.658 4.231-5.187 4.231-10.116v-3.771a.862.862 0 011.726 0v3.771c0 5.529-1.813 8.444-4.748 11.359l-1.684 1.671c-.13.129-.345.214-.605.214z"
          fill="url(#prefix__paint9_linear)"
        />
        <path
          d="M194.714 94.266h6.087c1.338 0 2.461-1.071 2.461-2.443v-.685a2.45 2.45 0 00-2.461-2.444h-6.087c-1.338 0-2.461 1.072-2.461 2.444v.685c.043 1.329 1.123 2.443 2.461 2.443z"
          fill="url(#prefix__paint10_linear)"
        />
        <path
          opacity={0.5}
          d="M191.994 60.02h-40.581a.523.523 0 01-.518-.514c0-.3.259-.514.518-.514h40.581c.302 0 .518.257.518.514 0 .3-.259.515-.518.515zM191.994 66.45h-40.581a.523.523 0 01-.518-.515c0-.3.259-.514.518-.514h40.581c.302 0 .518.257.518.514s-.259.515-.518.515zM191.994 72.836h-40.581a.523.523 0 01-.518-.514c0-.3.259-.515.518-.515h40.581c.302 0 .518.257.518.515 0 .3-.259.514-.518.514zM191.994 79.265h-40.581a.523.523 0 01-.518-.514c0-.3.259-.514.518-.514h40.581c.302 0 .518.257.518.514s-.259.514-.518.514zM191.994 85.651h-40.581a.523.523 0 01-.518-.514c0-.3.259-.514.518-.514h40.581c.302 0 .518.257.518.514 0 .3-.259.514-.518.514z"
          fill="#F1F4F9"
          fillOpacity={0.5}
        />
        <path
          d="M198.643 108.839v5.486c0 2.401-1.943 4.329-4.361 4.329h-46.107c-2.417 0-4.36-1.928-4.36-4.329v-5.486c0-2.4 1.943-4.329 4.36-4.329h46.107c2.375 0 4.274 1.843 4.361 4.158v.171z"
          fill="#777FD9"
        />
        <path
          d="M184.094 140.342c.834 0 1.511-.672 1.511-1.5 0-.829-.677-1.5-1.511-1.5-.835 0-1.511.671-1.511 1.5 0 .828.676 1.5 1.511 1.5z"
          fill="#74607E"
        />
        <path
          opacity={0.2}
          d="M198.643 111.454v2.871c0 2.401-1.943 4.329-4.361 4.329h-46.107c-2.417 0-4.36-1.928-4.36-4.329v-2.871h54.828z"
          fill="#A0759C"
        />
        <path
          d="M130.302 83.937H80.828a2.359 2.359 0 01-2.374-2.357V53.934a2.36 2.36 0 012.374-2.357h49.474a2.359 2.359 0 012.375 2.357V81.58a2.36 2.36 0 01-2.375 2.357z"
          fill="url(#prefix__paint11_linear)"
        />
        <path
          d="M119.423 73.779H91.707c-.82 0-1.51-.686-1.51-1.5v-4.586c0-.815.69-1.5 1.51-1.5h27.716c.821 0 1.511.685 1.511 1.5v4.586c0 .814-.69 1.5-1.511 1.5z"
          fill="url(#prefix__paint12_linear)"
        />
        <path
          d="M110.789 95.124h-10.447V71.122c0-.858.691-1.543 1.554-1.543h7.339c.864 0 1.554.685 1.554 1.543v24.002z"
          fill="url(#prefix__paint13_linear)"
        />
        <path
          d="M115.279 99.71H95.895c-.82 0-1.51-.686-1.51-1.5v-3.3c0-.815.69-1.5 1.51-1.5h19.384c.82 0 1.511.685 1.511 1.5v3.3c0 .814-.691 1.5-1.511 1.5z"
          fill="url(#prefix__paint14_linear)"
        />
        <path
          opacity={0.2}
          d="M105.566 63.964c2.527 0 4.576-2.034 4.576-4.544 0-2.509-2.049-4.543-4.576-4.543-2.528 0-4.577 2.034-4.577 4.543 0 2.51 2.049 4.544 4.577 4.544z"
          fill="#5C2C5B"
        />
        <path
          d="M108.372 57.02c0 .215-1.252.343-2.806.343-1.555 0-2.807-.171-2.807-.343 0-.214 1.252-.343 2.807-.343 1.554 0 2.806.172 2.806.343zM108.372 59.42c0 .215-1.252.343-2.806.343-1.555 0-2.807-.171-2.807-.343 0-.171 1.252-.343 2.807-.343 1.554-.042 2.806.13 2.806.343zM108.372 61.778c0 .214-1.252.343-2.806.343-1.555 0-2.807-.172-2.807-.343 0-.215 1.252-.343 2.807-.343 1.554 0 2.806.171 2.806.343z"
          fill="#4E355B"
        />
        <path d="M184.137 84.88h-8.289v11.015h8.289V84.88z" fill="#fff" />
        <path
          d="M181.158 89.295c-.777-2.358-3.453-3.772-5.914-3.172-2.504.6-3.886 3.043-3.109 5.4.778 2.358 3.454 3.772 5.915 3.172 2.504-.643 3.885-3.043 3.108-5.4zm-3.669 3.6c-1.468.343-2.979-.471-3.454-1.843-.432-1.372.345-2.786 1.813-3.129 1.468-.343 2.979.472 3.454 1.843.432 1.372-.389 2.786-1.813 3.129z"
          fill="#fff"
        />
        <path
          d="M151.284 97.181h-28.925a1.36 1.36 0 01-1.382-1.372 1.36 1.36 0 011.382-1.371h28.925c.777 0 1.381.6 1.381 1.371 0 .73-.648 1.372-1.381 1.372z"
          fill="url(#prefix__paint15_linear)"
        />
        <path
          d="M91.794 89.938h-21.11v6.6h21.11v-6.6z"
          fill="url(#prefix__paint16_linear)"
        />
        <path
          d="M90.585 91.223H72.151v-.471l18.434-.429v.9zM90.585 92.295H72.151v-.514l18.434.128v.386z"
          fill="#fff"
        />
        <path d="M205.291 95.724h-137.5V149h137.5V95.724z" fill="#D7E9FF" />
        <path d="M200.283 101.21H72.971v3.086h127.312v-3.086z" fill="#fff" />
      </g>
      <g clipPath="url(#prefix__clip2)">
        <path
          d="M19.826 108.896v1.015c0 .358.116.648.252.648h37.219c.145 0 .261-.29.261-.648v-1.015c0-.358-.116-.648-.261-.648h-37.22c-.135 0-.251.29-.251.648z"
          fill="url(#prefix__paint17_linear)"
        />
        <path
          d="M19.826 108.896v1.015c0 .358.106.648.232.648h29.554c.126 0 .233-.29.233-.648v-1.015c0-.358-.107-.648-.233-.648H20.058c-.126 0-.232.29-.232.648z"
          fill="url(#prefix__paint18_linear)"
        />
        <path
          d="M29.251 149.003h26.424v-38.441H29.252v38.441z"
          fill="url(#prefix__paint19_linear)"
        />
        <path
          d="M21.843 149.003h26.424v-38.441H21.842v38.441z"
          fill="#D7E9FF"
        />
        <path
          d="M23.265 111.642h23.586v35.23H23.266v-35.23zm23.324.261H23.517v34.728H46.6v-34.728h-.01z"
          fill="#fff"
        />
        <path
          d="M46.725 123.305H23.39v.251h23.334v-.251zM46.725 135.181H23.39v.251h23.334v-.251zM32.665 113.915h4.331a.378.378 0 100-.755h-4.331a.378.378 0 00-.378.378.366.366 0 00.378.377zM32.665 125.539h4.331a.378.378 0 100-.754h-4.331a.378.378 0 00-.378.377c-.01.203.165.377.378.377zM32.665 137.647h4.331a.378.378 0 000-.755h-4.331a.378.378 0 00-.378.377.367.367 0 00.378.378z"
          fill="#fff"
        />
      </g>
      <g clipPath="url(#prefix__clip3)">
        <path
          d="M217.67 28.017h-24.759a.406.406 0 01-.411-.411V3.595c0-.231.18-.411.411-.411h24.759c.232 0 .411.18.411.411v24.01a.413.413 0 01-.411.412z"
          fill="#C0D8FB"
        />
        <path
          d="M216.604 26.937H193.99a.373.373 0 01-.372-.372V4.636c0-.205.167-.373.372-.373h22.614c.205 0 .372.168.372.373v21.929a.373.373 0 01-.372.372z"
          fill="#D7E9FF"
        />
        <path
          d="M204.757 6.526c0-.296.232-.527.527-.527.296 0 .527.231.527.527a.521.521 0 01-.527.527.53.53 0 01-.527-.527zM209.036 7.271a.529.529 0 11.927.514.529.529 0 01-.927-.514zM212.698 10.189a.533.533 0 01.732.154.534.534 0 01-.154.733.535.535 0 01-.733-.154.534.534 0 01.155-.733zM214.509 14.688c.296 0 .527.231.527.527a.521.521 0 01-.527.527.52.52 0 01-.526-.527c0-.283.244-.527.526-.527zM213.584 19.92a.527.527 0 11-.745.745.527.527 0 01.745-.746zM210.257 23.261a.528.528 0 11-.746.743.528.528 0 01.746-.743zM204.757 24.983c0-.295.232-.527.527-.527.296 0 .527.232.527.527a.521.521 0 01-.527.527.53.53 0 01-.527-.527zM201.532 7.271a.529.529 0 10-.205.72.527.527 0 00.205-.72zM197.87 10.189a.533.533 0 00-.732.154.534.534 0 00.154.733c.244.154.578.09.733-.154a.534.534 0 00-.155-.733zM196.046 14.688a.522.522 0 00-.527.527c0 .296.232.527.527.527a.521.521 0 00.527-.527c.013-.283-.231-.527-.527-.527zM196.971 19.92a.528.528 0 10.746 0c-.193-.206-.527-.206-.746 0zM200.299 23.261a.527.527 0 10.745 0c-.193-.206-.527-.206-.745 0zM205.143 15.228c0-.296.231-.527.527-.527a.52.52 0 01.526.527.52.52 0 01-.526.527.538.538 0 01-.527-.527z"
          fill="#5384EE"
        />
        <path d="M205.747 8.595h-.142v6.62h.142v-6.62z" fill="#5384EE" />
        <path
          d="M208.982 12.293l-3.507 2.755.27.343 3.507-2.754-.27-.344z"
          fill="#5384EE"
        />
      </g>
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={88.576}
          y1={3.2}
          x2={88.576}
          y2={93.094}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE9FC" />
          <stop offset={1} stopColor="#DCE9FC" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={55.628}
          y1={114.536}
          x2={101.17}
          y2={13.167}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear"
          x1={51.02}
          y1={34.531}
          x2={98.929}
          y2={34.531}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear"
          x1={83.595}
          y1={65.141}
          x2={126.127}
          y2={65.141}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint4_linear"
          x1={51.954}
          y1={48.069}
          x2={124.721}
          y2={48.069}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint5_linear"
          x1={110}
          y1={63.675}
          x2={110}
          y2={149}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363636" />
          <stop offset={1} stopColor="#242424" />
        </linearGradient>
        <linearGradient
          id="prefix__paint6_linear"
          x1={171.358}
          y1={52.391}
          x2={171.358}
          y2={116.126}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="prefix__paint7_linear"
          x1={145.477}
          y1={90.195}
          x2={145.477}
          y2={108.11}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="prefix__paint8_linear"
          x1={144.096}
          y1={88.695}
          x2={144.096}
          y2={94.266}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="prefix__paint9_linear"
          x1={196.376}
          y1={90.237}
          x2={196.376}
          y2={108.11}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="prefix__paint10_linear"
          x1={197.758}
          y1={88.695}
          x2={197.758}
          y2={94.266}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="prefix__paint11_linear"
          x1={78.491}
          y1={67.772}
          x2={132.723}
          y2={67.772}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint12_linear"
          x1={105.565}
          y1={66.192}
          x2={105.565}
          y2={73.779}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint13_linear"
          x1={105.566}
          y1={69.579}
          x2={105.566}
          y2={95.124}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint14_linear"
          x1={105.587}
          y1={93.409}
          x2={105.587}
          y2={99.71}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint15_linear"
          x1={136.821}
          y1={94.438}
          x2={136.821}
          y2={97.181}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint16_linear"
          x1={81.239}
          y1={89.938}
          x2={81.239}
          y2={96.538}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint17_linear"
          x1={38.692}
          y1={108.248}
          x2={38.692}
          y2={110.559}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint18_linear"
          x1={34.835}
          y1={108.248}
          x2={34.835}
          y2={110.559}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7B9EDD" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint19_linear"
          x1={42.463}
          y1={149.003}
          x2={42.463}
          y2={110.562}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <clipPath id="prefix__clip0">
          <path
            fill="#fff"
            transform="translate(47.965)"
            d="M0 0h81.221v96.15H0z"
          />
        </clipPath>
        <clipPath id="prefix__clip1">
          <path
            fill="#fff"
            transform="translate(67.79 51.577)"
            d="M0 0h137.5v97.423H0z"
          />
        </clipPath>
        <clipPath id="prefix__clip2">
          <path
            fill="#fff"
            transform="translate(19.826 108.248)"
            d="M0 0h37.733v40.752H0z"
          />
        </clipPath>
        <clipPath id="prefix__clip3">
          <path
            fill="#fff"
            transform="translate(192.5 3.184)"
            d="M0 0h25.581v24.833H0z"
          />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width={220}
      height={149}
      viewBox="0 0 220 149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#prefix__clip0)">
        <path d="M129.186 0h-81.22v96.15h81.22V0z" fill="#F1F4F9" />
        <path
          d="M126.122 3.024H51.029v90.07h75.093V3.024z"
          fill="url(#prefix__paint0_linear)"
        />
        <path
          d="M65.467 53.525h-9.952v39.38h9.952v-39.38z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path d="M118.508 82.382H89.792v2.993h28.716v-2.993z" fill="#83B4FF" />
        <path
          d="M118.508 80.146H89.792v12.759h28.716v-12.76z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M94.12 67.04H68.31v25.896h25.81V67.04z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M91.75 46.657H70.68v25.896H91.75V46.657z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M87.834 40.986H74.564v35.789h13.269V40.986z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M79.43 28.164h-1.106v35.789h1.106V28.164zM84.137 36.828H83.03v35.788h1.106V36.828zM64.045 55.73l-6.824 2.143V43.57l6.824-2.142V55.73zM113.169 72.68h-19.08v10.396h19.08V72.679z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M111.464 51.54H98.258v35.946h13.206V51.54z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M109.758 61.968h-9.604V45.082l9.604 4.82v12.066z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M58.422 60.928h-1.2v3.214h1.2v-3.214zM61.075 60.928h-1.2v3.214h1.2v-3.214zM63.729 60.928h-1.2v3.214h1.2v-3.214zM58.422 66.473h-1.2v3.213h1.2v-3.213zM61.075 66.473h-1.2v3.213h1.2v-3.213zM63.729 66.473h-1.2v3.213h1.2v-3.213zM118.508 88.368H89.792v2.773h28.716v-2.773z"
          fill="#83B4FF"
        />
        <path
          d="M124.037 69.686h-8.561v23.219h8.561V69.686z"
          fill="#5384EE"
          fillOpacity={0.2}
        />
        <path
          d="M85.906 76.617h-10.93v16.288h10.93V76.617zM62.529 48.768h-2.054v4.757h2.054v-4.757zM91.75 54.722H70.68v4.064H91.75v-4.064zM102.334 54.722h-2.18v4.883h2.18v-4.883zM107.135 54.722h-2.179v4.883h2.179v-4.883zM102.334 61.527h-2.18v4.883h2.18v-4.883zM107.135 61.527h-2.179v4.883h2.179v-4.883zM102.334 68.3h-2.18v4.883h2.18V68.3zM107.135 68.3h-2.179v4.883h2.179V68.3zM102.334 75.105h-2.18v4.883h2.18v-4.883zM107.135 75.105h-2.179v4.883h2.179v-4.883zM102.334 81.91h-2.18v4.883h2.18V81.91zM107.135 81.91h-2.179v4.883h2.179V81.91z"
          fill="#83B4FF"
        />
        <path
          d="M126.122 3.024v5.608L61.897 93.094H51.03V74.57l54.4-71.546h20.692z"
          fill="url(#prefix__paint1_linear)"
        />
        <path
          d="M98.922 3.024L51.03 66.032V55.289L90.74 3.024h8.182z"
          fill="url(#prefix__paint2_linear)"
        />
        <path
          d="M126.122 37.175v10.774l-34.34 45.145H83.6l42.522-55.92z"
          fill="url(#prefix__paint3_linear)"
        />
        <path
          d="M124.732 3.024L56.21 93.094h-4.264l68.521-90.07h4.265z"
          fill="url(#prefix__paint4_linear)"
        />
      </g>
      <path
        d="M4.416 148.945s-13.239-32.637 6.13-52.127c24.979-25.132 57.253-4.12 69.363-4.738 12.111-.609 12.795-24.727 48.98-27.612 27.975-2.232 73.922-2.407 64.123 36.997-7.452 29.954 34.012 14.437 25.941 47.535l-214.537-.055z"
        fill="url(#prefix__paint5_linear)"
      />
      <g clipPath="url(#prefix__clip1)">
        <path
          opacity={0.2}
          d="M171.229 142.699c15.14 0 27.414-.671 27.414-1.5 0-.828-12.274-1.5-27.414-1.5-15.14 0-27.414.672-27.414 1.5 0 .829 12.274 1.5 27.414 1.5z"
          fill="#513CAF"
        />
        <path
          d="M171.229 133.913a2.36 2.36 0 01-2.375-2.357v-18.602h4.792v18.602c-.043 1.285-1.079 2.357-2.417 2.357z"
          fill="#4E355B"
        />
        <path
          d="M158.752 139.699a.825.825 0 01-.561-.214.823.823 0 01-.043-1.2l12.476-13.716c.173-.171.389-.3.648-.3s.475.086.647.257l12.865 13.716c.346.343.302.9-.043 1.2a.839.839 0 01-1.209-.043l-12.26-13.03-11.872 13.073a.959.959 0 01-.648.257z"
          fill="#4E355B"
        />
        <path
          opacity={0.2}
          d="M173.646 112.954h-4.792v8.786h4.792v-8.786z"
          fill="#5C2C5B"
        />
        <path
          d="M158.752 141.928c1.717 0 3.109-1.382 3.109-3.086s-1.392-3.086-3.109-3.086c-1.716 0-3.108 1.382-3.108 3.086s1.392 3.086 3.108 3.086z"
          fill="#4E355B"
        />
        <path
          d="M158.752 140.342c.835 0 1.511-.672 1.511-1.5 0-.829-.676-1.5-1.511-1.5-.834 0-1.511.671-1.511 1.5 0 .828.677 1.5 1.511 1.5z"
          fill="#74607E"
        />
        <path
          d="M184.094 141.928c1.716 0 3.108-1.382 3.108-3.086s-1.392-3.086-3.108-3.086c-1.717 0-3.109 1.382-3.109 3.086s1.392 3.086 3.109 3.086z"
          fill="#4E355B"
        />
        <path
          d="M194.887 116.126h-47.1s-1.424-21.002-1.424-38.233c0-8.786.388-15.258.733-19.501.303-3.386 3.152-6 6.606-6h35.357c3.453 0 6.303 2.614 6.605 6 .345 3.986.691 10.244.691 19.502-.043 19.201-1.468 38.232-1.468 38.232z"
          fill="url(#prefix__paint6_linear)"
        />
        <path
          d="M148.564 108.11a.852.852 0 01-.604-.257l-1.684-1.671c-2.935-2.915-4.749-5.829-4.749-11.358v-3.772a.863.863 0 011.727 0v3.772c0 4.971 1.554 7.457 4.231 10.115l1.684 1.671a.825.825 0 010 1.2.755.755 0 01-.605.3z"
          fill="url(#prefix__paint7_linear)"
        />
        <path
          d="M147.139 94.266h-6.087c-1.338 0-2.46-1.071-2.46-2.443v-.685a2.45 2.45 0 012.46-2.444h6.087c1.339 0 2.461 1.072 2.461 2.444v.685c-.043 1.329-1.122 2.443-2.461 2.443z"
          fill="url(#prefix__paint8_linear)"
        />
        <path
          d="M193.29 108.11a.855.855 0 01-.605-.257.825.825 0 010-1.2l1.684-1.671c2.677-2.658 4.231-5.187 4.231-10.116v-3.771a.863.863 0 011.727 0v3.771c0 5.529-1.814 8.444-4.749 11.359l-1.684 1.671c-.129.129-.345.214-.604.214z"
          fill="url(#prefix__paint9_linear)"
        />
        <path
          d="M194.714 94.266h6.087c1.339 0 2.461-1.071 2.461-2.443v-.685a2.45 2.45 0 00-2.461-2.444h-6.087c-1.338 0-2.461 1.072-2.461 2.444v.685c.044 1.329 1.123 2.443 2.461 2.443z"
          fill="url(#prefix__paint10_linear)"
        />
        <path
          opacity={0.5}
          d="M191.994 60.02h-40.58a.522.522 0 01-.518-.514c0-.3.259-.514.518-.514h40.58c.303 0 .518.257.518.514 0 .3-.259.515-.518.515zM191.994 66.45h-40.58a.522.522 0 01-.518-.515c0-.3.259-.514.518-.514h40.58c.303 0 .518.257.518.514s-.259.515-.518.515zM191.994 72.836h-40.58a.522.522 0 01-.518-.514c0-.3.259-.515.518-.515h40.58c.303 0 .518.257.518.515 0 .3-.259.514-.518.514zM191.994 79.265h-40.58a.522.522 0 01-.518-.514c0-.3.259-.514.518-.514h40.58c.303 0 .518.257.518.514s-.259.514-.518.514zM191.994 85.651h-40.58a.522.522 0 01-.518-.514c0-.3.259-.514.518-.514h40.58c.303 0 .518.257.518.514 0 .3-.259.514-.518.514z"
          fill="#F1F4F9"
          fillOpacity={0.5}
        />
        <path
          d="M198.643 108.839v5.486c0 2.401-1.943 4.329-4.361 4.329h-46.106c-2.418 0-4.361-1.928-4.361-4.329v-5.486c0-2.4 1.943-4.329 4.361-4.329h46.106c2.375 0 4.274 1.843 4.361 4.158v.171z"
          fill="#777FD9"
        />
        <path
          d="M184.094 140.342c.834 0 1.511-.672 1.511-1.5 0-.829-.677-1.5-1.511-1.5-.835 0-1.511.671-1.511 1.5 0 .828.676 1.5 1.511 1.5z"
          fill="#74607E"
        />
        <path
          opacity={0.2}
          d="M198.643 111.454v2.871c0 2.401-1.943 4.329-4.361 4.329h-46.107c-2.417 0-4.36-1.928-4.36-4.329v-2.871h54.828z"
          fill="#A0759C"
        />
        <path
          d="M130.302 83.937H80.828a2.359 2.359 0 01-2.374-2.357V53.934a2.36 2.36 0 012.374-2.357h49.474a2.359 2.359 0 012.375 2.357V81.58a2.36 2.36 0 01-2.375 2.357z"
          fill="url(#prefix__paint11_linear)"
        />
        <path
          d="M119.423 73.779H91.707c-.82 0-1.51-.686-1.51-1.5v-4.586c0-.815.69-1.5 1.51-1.5h27.716c.821 0 1.511.685 1.511 1.5v4.586c0 .814-.69 1.5-1.511 1.5z"
          fill="url(#prefix__paint12_linear)"
        />
        <path
          d="M110.789 95.124h-10.447V71.122c0-.858.691-1.543 1.554-1.543h7.339c.863 0 1.554.685 1.554 1.543v24.002z"
          fill="url(#prefix__paint13_linear)"
        />
        <path
          d="M115.279 99.71H95.895c-.82 0-1.51-.686-1.51-1.5v-3.3c0-.815.69-1.5 1.51-1.5h19.384c.82 0 1.511.685 1.511 1.5v3.3c0 .814-.691 1.5-1.511 1.5z"
          fill="url(#prefix__paint14_linear)"
        />
        <path
          opacity={0.2}
          d="M105.566 63.964c2.527 0 4.576-2.034 4.576-4.544 0-2.509-2.049-4.543-4.576-4.543-2.528 0-4.576 2.034-4.576 4.543 0 2.51 2.048 4.544 4.576 4.544z"
          fill="#5C2C5B"
        />
        <path
          d="M108.372 57.02c0 .215-1.252.343-2.806.343-1.555 0-2.806-.171-2.806-.343 0-.214 1.251-.343 2.806-.343 1.554 0 2.806.172 2.806.343zM108.372 59.42c0 .215-1.252.343-2.806.343-1.555 0-2.806-.171-2.806-.343 0-.171 1.251-.343 2.806-.343 1.554-.042 2.806.13 2.806.343zM108.372 61.778c0 .214-1.252.343-2.806.343-1.555 0-2.806-.172-2.806-.343 0-.215 1.251-.343 2.806-.343 1.554 0 2.806.171 2.806.343z"
          fill="#4E355B"
        />
        <path d="M184.137 84.88h-8.289v11.015h8.289V84.88z" fill="#fff" />
        <path
          d="M181.158 89.295c-.777-2.358-3.453-3.772-5.914-3.172-2.504.6-3.886 3.043-3.109 5.4.778 2.358 3.454 3.772 5.915 3.172 2.504-.643 3.885-3.043 3.108-5.4zm-3.669 3.6c-1.468.343-2.979-.471-3.454-1.843-.432-1.372.345-2.786 1.813-3.129 1.468-.343 2.979.472 3.454 1.843.432 1.372-.389 2.786-1.813 3.129z"
          fill="#fff"
        />
        <path
          d="M151.284 97.181h-28.925c-.777 0-1.381-.6-1.381-1.372 0-.771.604-1.371 1.381-1.371h28.925c.777 0 1.381.6 1.381 1.371 0 .73-.647 1.372-1.381 1.372z"
          fill="url(#prefix__paint15_linear)"
        />
        <path
          d="M91.794 89.938h-21.11v6.6h21.11v-6.6z"
          fill="url(#prefix__paint16_linear)"
        />
        <path
          d="M90.585 91.223H72.151v-.471l18.434-.429v.9zM90.585 92.295H72.151v-.514l18.434.128v.386z"
          fill="#fff"
        />
        <path d="M205.291 95.724h-137.5V149h137.5V95.724z" fill="#D7E9FF" />
        <path d="M200.283 101.21H72.971v3.086h127.312v-3.086z" fill="#fff" />
      </g>
      <g clipPath="url(#prefix__clip2)">
        <path
          d="M19.826 108.896v1.015c0 .358.116.648.252.648h37.219c.145 0 .261-.29.261-.648v-1.015c0-.358-.116-.648-.261-.648h-37.22c-.135 0-.251.29-.251.648z"
          fill="url(#prefix__paint17_linear)"
        />
        <path
          d="M19.826 108.896v1.015c0 .358.106.648.232.648h29.554c.127 0 .233-.29.233-.648v-1.015c0-.358-.106-.648-.233-.648H20.058c-.126 0-.232.29-.232.648z"
          fill="url(#prefix__paint18_linear)"
        />
        <path
          d="M29.251 149.003h26.424v-38.441H29.252v38.441z"
          fill="url(#prefix__paint19_linear)"
        />
        <path
          d="M21.843 149.003h26.424v-38.441H21.843v38.441z"
          fill="#D7E9FF"
        />
        <path
          d="M23.266 111.642H46.85v35.23H23.266v-35.23zm23.323.261H23.518v34.728h23.081v-34.728h-.01z"
          fill="#fff"
        />
        <path
          d="M46.725 123.305H23.39v.251h23.334v-.251zM46.725 135.181H23.39v.251h23.334v-.251zM32.665 113.915h4.332a.378.378 0 000-.755h-4.332a.378.378 0 00-.378.378.366.366 0 00.378.377zM32.665 125.539h4.332a.378.378 0 100-.754h-4.332a.378.378 0 00-.378.377c-.01.203.165.377.378.377zM32.665 137.647h4.332a.378.378 0 000-.755h-4.332a.378.378 0 00-.378.377.367.367 0 00.378.378z"
          fill="#fff"
        />
      </g>
      <g clipPath="url(#prefix__clip3)">
        <path
          d="M217.67 28.017h-24.759a.406.406 0 01-.411-.411V3.595c0-.231.18-.411.411-.411h24.759c.232 0 .411.18.411.411v24.01a.413.413 0 01-.411.412z"
          fill="#C0D8FB"
        />
        <path
          d="M216.604 26.937H193.99a.373.373 0 01-.372-.372V4.636c0-.205.167-.373.372-.373h22.614c.205 0 .372.168.372.373v21.929a.373.373 0 01-.372.372z"
          fill="#D7E9FF"
        />
        <path
          d="M204.757 6.526c0-.296.232-.527.527-.527.296 0 .527.231.527.527a.521.521 0 01-.527.527.53.53 0 01-.527-.527zM209.036 7.271a.529.529 0 11.927.514.529.529 0 01-.927-.514zM212.698 10.189a.533.533 0 01.732.154.534.534 0 01-.154.733.535.535 0 01-.733-.154.534.534 0 01.155-.733zM214.509 14.688c.296 0 .527.231.527.527a.521.521 0 01-.527.527.52.52 0 01-.526-.527c0-.283.244-.527.526-.527zM213.584 19.92a.527.527 0 11-.745.745.527.527 0 01.745-.746zM210.257 23.261a.528.528 0 11-.746.743.528.528 0 01.746-.743zM204.757 24.983c0-.295.232-.527.527-.527.296 0 .527.232.527.527a.521.521 0 01-.527.527.53.53 0 01-.527-.527zM201.532 7.271a.529.529 0 10-.205.72.527.527 0 00.205-.72zM197.87 10.189a.533.533 0 00-.732.154.534.534 0 00.154.733c.244.154.578.09.733-.154a.534.534 0 00-.155-.733zM196.046 14.688a.522.522 0 00-.527.527c0 .296.232.527.527.527a.521.521 0 00.527-.527c.013-.283-.231-.527-.527-.527zM196.971 19.92a.528.528 0 10.746 0c-.193-.206-.527-.206-.746 0zM200.299 23.261a.527.527 0 10.745 0c-.193-.206-.527-.206-.745 0zM205.143 15.228c0-.296.231-.527.527-.527a.52.52 0 01.526.527.52.52 0 01-.526.527.538.538 0 01-.527-.527z"
          fill="#5384EE"
        />
        <path d="M205.747 8.595h-.142v6.62h.142v-6.62z" fill="#5384EE" />
        <path
          d="M208.982 12.293l-3.507 2.755.27.343 3.507-2.754-.27-.344z"
          fill="#5384EE"
        />
      </g>
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={88.576}
          y1={3.2}
          x2={88.576}
          y2={93.094}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE9FC" />
          <stop offset={1} stopColor="#DCE9FC" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={55.628}
          y1={114.536}
          x2={101.17}
          y2={13.167}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear"
          x1={51.02}
          y1={34.531}
          x2={98.929}
          y2={34.531}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear"
          x1={83.595}
          y1={65.141}
          x2={126.127}
          y2={65.141}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint4_linear"
          x1={51.954}
          y1={48.069}
          x2={124.721}
          y2={48.069}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint5_linear"
          x1={110}
          y1={63.841}
          x2={110}
          y2={149}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE9FC" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__paint6_linear"
          x1={171.359}
          y1={52.391}
          x2={171.359}
          y2={116.126}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="prefix__paint7_linear"
          x1={145.478}
          y1={90.195}
          x2={145.478}
          y2={108.11}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="prefix__paint8_linear"
          x1={144.096}
          y1={88.695}
          x2={144.096}
          y2={94.266}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="prefix__paint9_linear"
          x1={196.376}
          y1={90.237}
          x2={196.376}
          y2={108.11}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="prefix__paint10_linear"
          x1={197.758}
          y1={88.695}
          x2={197.758}
          y2={94.266}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6D8CC2" />
          <stop offset={1} stopColor="#98C0E8" />
        </linearGradient>
        <linearGradient
          id="prefix__paint11_linear"
          x1={78.491}
          y1={67.772}
          x2={132.723}
          y2={67.772}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="prefix__paint12_linear"
          x1={105.565}
          y1={66.192}
          x2={105.565}
          y2={73.779}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint13_linear"
          x1={105.566}
          y1={69.579}
          x2={105.566}
          y2={95.124}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint14_linear"
          x1={105.587}
          y1={93.409}
          x2={105.587}
          y2={99.71}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint15_linear"
          x1={136.821}
          y1={94.438}
          x2={136.821}
          y2={97.181}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint16_linear"
          x1={81.239}
          y1={89.938}
          x2={81.239}
          y2={96.538}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint17_linear"
          x1={38.692}
          y1={108.248}
          x2={38.692}
          y2={110.559}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint18_linear"
          x1={34.835}
          y1={108.248}
          x2={34.835}
          y2={110.559}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7B9EDD" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="prefix__paint19_linear"
          x1={42.463}
          y1={149.003}
          x2={42.463}
          y2={110.562}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <clipPath id="prefix__clip0">
          <path
            fill="#fff"
            transform="translate(47.965)"
            d="M0 0h81.221v96.15H0z"
          />
        </clipPath>
        <clipPath id="prefix__clip1">
          <path
            fill="#fff"
            transform="translate(67.79 51.577)"
            d="M0 0h137.5v97.423H0z"
          />
        </clipPath>
        <clipPath id="prefix__clip2">
          <path
            fill="#fff"
            transform="translate(19.826 108.248)"
            d="M0 0h37.733v40.752H0z"
          />
        </clipPath>
        <clipPath id="prefix__clip3">
          <path
            fill="#fff"
            transform="translate(192.5 3.184)"
            d="M0 0h25.581v24.833H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgNotFoundProfile;
