'use client';

import { routeNames, useTranslation } from '@lobox/utils';
import SvgNotFoundProfile from '@shared/svg/SvgNotFoundProfile';
import { useRouter } from 'next/navigation';
import NotFound from '@lobox/uikit/NotFound';

export default function NotFoundP() {
  const { t } = useTranslation();
  const router = useRouter();
  //TODO
  const isPage = true;

  const onBackHandler = () => router.back();
  const onClickHomeHandler = () => router.push(routeNames.home);

  return (
    <NotFound
      title={
        isPage ? t('not_found_profile_page') : t('not_found_profile_title')
      }
      message={t('not_fount_profile_message')}
      image={<SvgNotFoundProfile />}
      secondaryActionProps={{
        label: t('back'),
        onClick: onBackHandler,
      }}
      primaryActionProps={{
        label: t('go_to_home_p'),
        onClick: onClickHomeHandler,
      }}
    />
  );
}
